/* width */
.scrollbarStyle::-webkit-scrollbar {
    width: 10px;
}
  
  /* Track */
.scrollbarStyle::-webkit-scrollbar-track {
    background: #fff;
}
  
  /* Handle */
.scrollbarStyle::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    border-radius: 10px;
}
  
  /* Handle on hover */
.scrollbarStyle::-webkit-scrollbar-thumb:hover {
    background: #888;      
}
$colors: (
    'blue': #2196f3,
    'green': #4caf50,
    'yellow': #fbc02d,
    'cyan': #00bcd4,
    'pink': #e91e63,
    'indigo': #3f51b5,
    'teal': #009688,
    'orange': #f57c00,
    'bluegray': #607d8b,
    'purple': #9c27b0,
    'red': #d32f2f,
    'primary': $primaryColor
);

//reused color variables
$shade000: #ffffff !default; //surface
$shade100: #fcfcfc !default; //header background
$shade200: #d8dadd !default;
$shade300: #d4d6d9 !default; //border, divider
$shade400: #d4d6d9 !default; //input border
$shade500: #545b67 !default; //input icon
$shade600: #83888f !default; //text secondary color
$shade700: #69707a !default; //text color
$shade800: #343a40 !default; //unused
$shade900: #212529 !default; //unused

$hoverBg: rgba($primaryColor, 0.2) !default; //hover background

//global
$fontFamily: 'Inter var', sans-serif !default;
$fontSize: 1rem !default;
$fontWeight: normal !default;
$textColor: $shade700 !default;
$textSecondaryColor: $shade600 !default;
$borderRadius: 10px !default;
$transitionDuration: 0.2s !default;
$formElementTransition: background-color $transitionDuration, color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration !default;
$actionIconTransition: background-color $transitionDuration, color $transitionDuration, box-shadow $transitionDuration !default;
$listItemTransition: box-shadow $transitionDuration !default;
$primeIconFontSize: 1rem !default;
$divider: 1px solid $shade300 !default;
$inlineSpacing: 0.5rem !default;
$disabledOpacity: 0.6 !default;
$maskBg: rgba(0, 0, 0, 0.4) !default;
$loadingIconFontSize: 2rem !default;
$errorColor: #e24c4c !default;

//selected state
$highlightBg: rgba($primaryColor, 0.12);
$highlightTextColor: $textColor;
$highlightFocusBg: rgba($primaryColor, 0.24);

//scale
$scaleSM:0.875 !default;
$scaleLG:1.25 !default;

//focus
$focusOutlineColor:$primaryLightColor !default;
$focusOutline:0 none !default;
$focusOutlineOffset:0 !default;
$focusShadow:0 0 0 0.2rem $focusOutlineColor !default;

//action icons
$actionIconWidth:2rem !default;
$actionIconHeight:2rem !default;
$actionIconBg:transparent !default;
$actionIconBorder:0 none !default;
$actionIconColor:$shade600 !default;
$actionIconHoverBg:$hoverBg !default;
$actionIconHoverBorderColor:transparent !default;
$actionIconHoverColor:$shade800 !default;
$actionIconBorderRadius:50% !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding:.75rem .75rem !default;
$inputTextFontSize:1rem !default;

$inputBg:$shade000 !default;
$inputTextColor:$shade700 !default;
$inputIconColor:$shade600 !default;
$inputBorder:1px solid $shade400 !default;
$inputHoverBorderColor:$primaryColor !default;
$inputFocusBorderColor:$primaryColor !default;
$inputErrorBorderColor:$errorColor !default;
$inputPlaceholderTextColor:$shade600 !default;
$inputFilledBg:#f2f4f6 !default;
$inputFilledHoverBg:$inputFilledBg !default;
$inputFilledFocusBg:$shade000 !default;

//input groups
$inputGroupBg:#e6e8ea !default;
$inputGroupTextColor:$shade600 !default;
$inputGroupAddOnMinWidth:3rem !default;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg:$shade000 !default;
$inputListTextColor:$shade700 !default;
$inputListBorder:$inputBorder !default;
$inputListPadding:.75rem 0 !default;
$inputListItemPadding:.75rem 1.25rem !default;
$inputListItemBg:transparent !default;
$inputListItemTextColor:$shade700 !default;
$inputListItemHoverBg:$hoverBg !default;
$inputListItemTextHoverColor:$shade700 !default;
$inputListItemFocusBg:$shade300 !default;
$inputListItemTextFocusColor:$shade700 !default;
$inputListItemBorder:0 none !default;
$inputListItemBorderRadius:0 !default;
$inputListItemMargin:0 !default;
$inputListItemFocusShadow:inset 0 0 0 0.15rem $focusOutlineColor !default;
$inputListHeaderPadding:.75rem 1.25rem !default;
$inputListHeaderMargin:0 !default;
$inputListHeaderBg:$shade100 !default;
$inputListHeaderTextColor:$shade700 !default;
$inputListHeaderBorder:1px solid $shade300 !default;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg:$inputListBg !default;
$inputOverlayHeaderBg:$inputListHeaderBg !default;
$inputOverlayBorder:0 none !default;
$inputOverlayShadow:0 2px 12px 0 rgba(0, 0, 0, 0.1) !default;

//password
$passwordMeterBg:$shade300 !default;
$passwordWeakBg:#ea5455 !default;
$passwordMediumBg:#ff9f42 !default;
$passwordStrongBg:#29c76f !default;

//button
$buttonPadding:.75rem 1.25rem !default;
$buttonIconOnlyWidth:3rem !default;
$buttonIconOnlyPadding:.75rem 0 !default;
$buttonBg:$primaryColor !default;
$buttonTextColor:$primaryTextColor !default;
$buttonBorder:1px solid $primaryColor !default;
$buttonHoverBg:$primaryDarkColor !default;
$buttonTextHoverColor:$primaryTextColor !default;
$buttonHoverBorderColor:$primaryDarkColor !default;
$buttonActiveBg:$primaryDarkerColor !default;
$buttonTextActiveColor:$primaryTextColor !default;
$buttonActiveBorderColor:$primaryDarkerColor !default;
$raisedButtonShadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !default;
$roundedButtonBorderRadius:2rem !default;

$textButtonHoverBgOpacity: 0.04 !default;
$textButtonActiveBgOpacity: 0.16 !default;
$outlinedButtonBorder: 1px solid !default;
$plainButtonTextColor: $textSecondaryColor !default;
$plainButtonHoverBgColor: $hoverBg !default;
$plainButtonActiveBgColor: $shade300 !default;

$secondaryButtonBg: #607d8b !default;
$secondaryButtonTextColor: #ffffff !default;
$secondaryButtonBorder: 1px solid $secondaryButtonBg !default;
$secondaryButtonHoverBg: #475569 !default;
$secondaryButtonTextHoverColor: $secondaryButtonTextColor !default;
$secondaryButtonHoverBorderColor: #475569 !default;
$secondaryButtonActiveBg: #334155 !default;
$secondaryButtonTextActiveColor: $secondaryButtonTextColor !default;
$secondaryButtonActiveBorderColor: #334155 !default;
$secondaryButtonFocusShadow: 0 0 0 0.2rem #e2e8f0 !default;

$infoButtonBg: #0288d1 !default;
$infoButtonTextColor: #ffffff !default;
$infoButtonBorder: 1px solid $infoButtonBg !default;
$infoButtonHoverBg: #2563eb !default;
$infoButtonTextHoverColor: $infoButtonTextColor !default;
$infoButtonHoverBorderColor: #2563eb !default;
$infoButtonActiveBg: #1d4ed8 !default;
$infoButtonTextActiveColor: $infoButtonTextColor !default;
$infoButtonActiveBorderColor: #1d4ed8 !default;
$infoButtonFocusShadow: 0 0 0 0.2rem #bfdbfe !default;

$successButtonBg: #689f38 !default;
$successButtonTextColor: #ffffff !default;
$successButtonBorder: 1px solid $successButtonBg !default;
$successButtonHoverBg: #16a34a !default;
$successButtonTextHoverColor: $successButtonTextColor !default;
$successButtonHoverBorderColor: #16a34a !default;
$successButtonActiveBg: #15803d !default;
$successButtonTextActiveColor: $successButtonTextColor !default;
$successButtonActiveBorderColor: #15803d !default;
$successButtonFocusShadow: 0 0 0 0.2rem #bbf7d0 !default;

$warningButtonBg: #fbc02d !default;
$warningButtonTextColor: $shade900 !default;
$warningButtonBorder: 1px solid $warningButtonBg !default;
$warningButtonHoverBg: #d97706 !default;
$warningButtonTextHoverColor: $warningButtonTextColor !default;
$warningButtonHoverBorderColor: #d97706 !default;
$warningButtonActiveBg: #b45309 !default;
$warningButtonTextActiveColor: $warningButtonTextColor !default;
$warningButtonActiveBorderColor: #b45309 !default;
$warningButtonFocusShadow: 0 0 0 0.2rem #fde68a !default;

$helpButtonBg: #9c27b0 !default;
$helpButtonTextColor: #ffffff !default;
$helpButtonBorder: 1px solid $helpButtonBg !default;
$helpButtonHoverBg: #9333ea !default;
$helpButtonTextHoverColor: $helpButtonTextColor !default;
$helpButtonHoverBorderColor: #9333ea !default;
$helpButtonActiveBg: #7e22ce !default;
$helpButtonTextActiveColor: $helpButtonTextColor !default;
$helpButtonActiveBorderColor: #7e22ce !default;
$helpButtonFocusShadow: 0 0 0 0.2rem #e9d5ff !default;

$dangerButtonBg: #d32f2f !default;
$dangerButtonTextColor: #ffffff !default;
$dangerButtonBorder: 1px solid $dangerButtonBg !default;
$dangerButtonHoverBg: #dc2626 !default;
$dangerButtonTextHoverColor: $dangerButtonTextColor !default;
$dangerButtonHoverBorderColor: #dc2626 !default;
$dangerButtonActiveBg: #b91c1c !default;
$dangerButtonTextActiveColor: $dangerButtonTextColor !default;
$dangerButtonActiveBorderColor: #b91c1c !default;
$dangerButtonFocusShadow: 0 0 0 0.2rem #fecaca !default;

$linkButtonColor:$primaryDarkerColor !default;
$linkButtonHoverColor:$primaryDarkerColor !default;
$linkButtonTextHoverDecoration:underline !default;
$linkButtonFocusShadow:0 0 0 0.2rem $focusOutlineColor !default;

//checkbox
$checkboxWidth:22px !default;
$checkboxHeight:22px !default;
$checkboxBorder:2px solid $shade400 !default;
$checkboxBorderRadius: 6px !default;
$checkboxIconFontSize:14px !default;
$checkboxActiveBorderColor:$primaryColor !default;
$checkboxActiveBg:$primaryColor !default;
$checkboxIconActiveColor:$primaryTextColor !default;
$checkboxActiveHoverBg:$primaryDarkerColor !default;
$checkboxIconActiveHoverColor:$primaryTextColor !default;
$checkboxActiveHoverBorderColor:$primaryDarkerColor !default;

//radiobutton
$radiobuttonWidth:22px !default;
$radiobuttonHeight:22px !default;
$radiobuttonBorder:2px solid $shade400 !default;
$radiobuttonIconSize:12px !default;
$radiobuttonActiveBorderColor:$primaryColor !default;
$radiobuttonActiveBg:$primaryColor !default;
$radiobuttonIconActiveColor:$primaryTextColor !default;
$radiobuttonActiveHoverBg:$primaryDarkerColor !default;
$radiobuttonIconActiveHoverColor:$primaryTextColor !default;
$radiobuttonActiveHoverBorderColor:$primaryDarkerColor !default;

//colorpicker
$colorPickerPreviewWidth:2rem !default;
$colorPickerPreviewHeight:2rem !default;
$colorPickerBg:#323232 !default;
$colorPickerBorder:1px solid #191919 !default;
$colorPickerHandleColor:$shade000 !default;

//togglebutton
$toggleButtonBg:$inputBg !default;
$toggleButtonBorder:1px solid $shade400 !default;
$toggleButtonTextColor:$shade700 !default;
$toggleButtonIconColor:$shade600 !default;
$toggleButtonHoverBg:$hoverBg !default;
$toggleButtonHoverBorderColor:$shade400 !default;
$toggleButtonTextHoverColor:$shade700 !default;
$toggleButtonIconHoverColor:$shade800 !default;
$toggleButtonActiveBg:$primaryColor !default;
$toggleButtonActiveBorderColor:$primaryColor !default;
$toggleButtonTextActiveColor:$primaryTextColor !default;
$toggleButtonIconActiveColor:$primaryTextColor !default;
$toggleButtonActiveHoverBg:$primaryDarkColor !default;
$toggleButtonActiveHoverBorderColor:$primaryDarkColor !default;
$toggleButtonTextActiveHoverColor:$primaryTextColor !default;
$toggleButtonIconActiveHoverColor:$primaryTextColor !default;

//inplace
$inplacePadding:$inputPadding !default;
$inplaceHoverBg:$hoverBg !default;
$inplaceTextHoverColor:$shade700 !default;

//rating
$ratingIconFontSize:1.143rem !default;
$ratingCancelIconColor:#e74c3c !default;
$ratingCancelIconHoverColor:#c0392b !default;
$ratingStarIconOffColor:$shade700 !default;
$ratingStarIconOnColor:$primaryColor !default;
$ratingStarIconHoverColor:$primaryColor !default;

//slider
$sliderBg:$shade300 !default;
$sliderBorder:0 none !default;
$sliderHorizontalHeight:.286rem !default;
$sliderVerticalWidth:0.286rem !default;
$sliderHandleWidth:1.143rem !default;
$sliderHandleHeight:1.143rem !default;
$sliderHandleBg:$shade000 !default;
$sliderHandleBorder:2px solid $primaryColor !default;
$sliderHandleBorderRadius:50% !default;
$sliderHandleHoverBorderColor:$primaryColor !default;
$sliderHandleHoverBg:$primaryColor !default;
$sliderRangeBg:$primaryColor !default;

//calendar
$calendarTableMargin:.5rem 0 !default;
$calendarPadding:.5rem !default;
$calendarBg:$shade000 !default;
$calendarInlineBg:$calendarBg !default;
$calendarTextColor:$shade700 !default;
$calendarBorder:$inputListBorder !default;
$calendarOverlayBorder:$inputOverlayBorder !default;

$calendarHeaderPadding:.5rem !default;
$calendarHeaderBg:$shade000 !default;
$calendarInlineHeaderBg:$calendarBg !default;
$calendarHeaderBorder:1px solid $shade300 !default;
$calendarHeaderTextColor:$shade700 !default;
$calendarHeaderFontWeight:600 !default;
$calendarHeaderCellPadding:.5rem !default;
$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;

$calendarCellDatePadding:.5rem !default;
$calendarCellDateWidth:2.5rem !default;
$calendarCellDateHeight:2.5rem !default;
$calendarCellDateBorderRadius:50% !default;
$calendarCellDateBorder:1px solid transparent !default;
$calendarCellDateHoverBg:$hoverBg !default;
$calendarCellDateTodayBg:$shade400 !default;
$calendarCellDateTodayBorderColor:transparent !default;
$calendarCellDateTodayTextColor:$shade700 !default;

$calendarButtonBarPadding:1rem 0 !default;
$calendarTimePickerPadding:.5rem !default;
$calendarTimePickerElementPadding:0 .5rem !default;
$calendarTimePickerTimeFontSize:1.25rem !default;

$calendarBreakpoint:769px !default;
$calendarCellDatePaddingSM:0 !default;

//input switch
$inputSwitchWidth:3rem !default;
$inputSwitchHeight:1.75rem !default;
$inputSwitchBorderRadius:30px !default;
$inputSwitchHandleWidth:1.250rem !default;
$inputSwitchHandleHeight:1.250rem !default;
$inputSwitchHandleBorderRadius:50% !default;
$inputSwitchSliderPadding:.25rem !default;
$inputSwitchSliderOffBg:$shade400 !default;
$inputSwitchHandleOffBg:$shade000 !default;
$inputSwitchSliderOffHoverBg:scale-color($shade400, $lightness: -10%) !default;
$inputSwitchSliderOnBg:$primaryColor !default;
$inputSwitchSliderOnHoverBg:$primaryDarkColor !default;
$inputSwitchHandleOnBg:$shade000 !default;

//panel
$panelHeaderBorderColor:$shade300 !default;
$panelHeaderBorder:1px solid $shade300 !default;
$panelHeaderBg:$shade100 !default;
$panelHeaderTextColor:$shade800 !default;
$panelHeaderFontWeight:700 !default;
$panelHeaderPadding:1.25rem !default;
$panelToggleableHeaderPadding:.75rem 1.25rem !default;

$panelHeaderHoverBg:$hoverBg !default;
$panelHeaderHoverBorderColor:$shade300 !default;
$panelHeaderTextHoverColor:$shade800 !default;

$panelContentBorder:1px solid $shade300 !default;
$panelContentBorderColor: $shade300 !default;
$panelContentBorder:1px solid $shade300 !default;
$panelContentBg:$shade000 !default;
$panelContentEvenRowBg:scale-color($panelContentBg, $lightness: -1%) !default;
$panelContentTextColor:$shade700 !default;
$panelContentPadding:1rem !default;

$panelFooterBorder:1px solid $shade300 !default;
$panelFooterBg:$shade000 !default;
$panelFooterTextColor:$shade700 !default;
$panelFooterPadding:0.5rem 1rem !default;

//accordion
$accordionSpacing:4px !default;
$accordionHeaderBorder:$panelHeaderBorder !default;
$accordionHeaderBg:$panelHeaderBg !default;
$accordionHeaderTextColor:$shade600 !default;
$accordionHeaderFontWeight:$panelHeaderFontWeight !default;
$accordionHeaderPadding:$panelHeaderPadding !default;

$accordionHeaderHoverBg:$hoverBg !default;
$accordionHeaderHoverBorderColor:$shade300 !default;
$accordionHeaderTextHoverColor:$shade800 !default;

$accordionHeaderActiveBg:$panelHeaderBg !default;
$accordionHeaderActiveBorderColor:$shade300 !default;
$accordionHeaderTextActiveColor:$shade800 !default;

$accordionHeaderActiveHoverBg:$hoverBg !default;
$accordionHeaderActiveHoverBorderColor:$shade300 !default;
$accordionHeaderTextActiveHoverColor:$shade800 !default;

$accordionContentBorder:$panelContentBorder !default;
$accordionContentBg:$panelContentBg !default;
$accordionContentTextColor:$panelContentTextColor !default;
$accordionContentPadding:$panelContentPadding !default;

//tabview
$tabviewNavBorder:1px solid $shade300 !default;
$tabviewNavBorderWidth:0 0 2px 0 !default;
$tabviewNavBg:$shade000 !default;

$tabviewHeaderSpacing:0 !default;
$tabviewHeaderBorder:solid $shade300 !default;
$tabviewHeaderBorderWidth:0 0 2px 0 !default;
$tabviewHeaderBorderColor:transparent transparent $shade300 transparent !default;
$tabviewHeaderBg:$shade000 !default;
$tabviewHeaderTextColor:$shade600 !default;
$tabviewHeaderFontWeight:$panelHeaderFontWeight !default;
$tabviewHeaderPadding:$panelHeaderPadding !default;
$tabviewHeaderMargin:0 0 -2px 0 !default;

$tabviewHeaderHoverBg:$shade000 !default;
$tabviewHeaderHoverBorderColor:$shade500 !default;
$tabviewHeaderTextHoverColor:$shade600 !default;

$tabviewHeaderActiveBg:$shade000 !default;
$tabviewHeaderActiveBorderColor:$primaryColor !default;
$tabviewHeaderTextActiveColor:$primaryColor !default;

$tabviewContentBorder:0 none !default;
$tabviewContentBg:$panelContentBg !default;
$tabviewContentTextColor:$panelContentTextColor !default;
$tabviewContentPadding:$panelContentPadding !default;

//upload
$fileUploadProgressBarHeight:.25rem !default;
$fileUploadContentPadding: 2rem 1rem !default;
$fileUploadContentHoverBorder: 1px dashed $primaryColor !default;
$fileUploadFileBorder: 1px solid $shade300 !default;
$fileUploadFilePadding: 1rem !default;

//scrollpanel
$scrollPanelTrackBorder:0 none !default;
$scrollPanelTrackBg:$shade100 !default;

//card
$cardBodyPadding:1.25rem !default;
$cardTitleFontSize:1.5rem !default;
$cardTitleFontWeight:700 !default;
$cardSubTitleFontWeight:400 !default;
$cardSubTitleColor:$shade600 !default;
$cardContentPadding:1.25rem 0 !default;
$cardFooterPadding:1.25rem 0 0 0 !default;
$cardShadow: 0px 10px 40px rgba(41, 50, 65, 0.06) !default;

//editor
$editorToolbarBg:$panelHeaderBg !default;
$editorToolbarBorder:$panelHeaderBorder !default;
$editorToolbarPadding:$panelHeaderPadding !default;
$editorToolbarIconColor:$textSecondaryColor !default;
$editorToolbarIconHoverColor:$textColor !default;
$editorIconActiveColor:$primaryColor !default;
$editorContentBorder:$panelContentBorder !default;
$editorContentBg:$panelContentBg !default;

//paginator
$paginatorBg:$shade000 !default;
$paginatorTextColor:$shade600 !default;
$paginatorBorder:solid $shade200 !default;
$paginatorBorderWidth:0 !default;
$paginatorPadding:.5rem 1rem !default;
$paginatorElementWidth:$buttonIconOnlyWidth !default;
$paginatorElementHeight:$buttonIconOnlyWidth !default;
$paginatorElementBg:transparent !default;
$paginatorElementBorder:0 none !default;
$paginatorElementIconColor:$shade600 !default;
$paginatorElementHoverBg:$hoverBg !default;
$paginatorElementHoverBorderColor:transparent !default;
$paginatorElementIconHoverColor:$shade800 !default;
$paginatorElementBorderRadius:50% !default;
$paginatorElementMargin:.143rem !default;
$paginatorElementPadding:0 !default;

//table
$tableHeaderBorder:1px solid $shade300 !default;
$tableHeaderBorderWidth:1px 0 1px 0 !default;
$tableHeaderBg:$shade100 !default;
$tableHeaderTextColor:$shade800 !default;
$tableHeaderFontWeight:700 !default;
$tableHeaderPadding:1rem 1rem !default;

$tableHeaderCellPadding:1rem 1rem !default;
$tableHeaderCellBg:$shade100 !default;
$tableHeaderCellTextColor:$shade800 !default;
$tableHeaderCellFontWeight:600 !default;
$tableHeaderCellBorder:1px solid $shade300 !default;
$tableHeaderCellBorderWidth:0 0 1px 0 !default;
$tableHeaderCellHoverBg:$hoverBg !default;
$tableHeaderCellTextHoverColor:$shade800 !default;
$tableHeaderCellIconColor:$shade800 !default;
$tableHeaderCellIconHoverColor:$shade800 !default;
$tableHeaderCellHighlightBg:$shade100 !default;
$tableHeaderCellHighlightTextColor: $highlightTextColor !default;
$tableHeaderCellHighlightHoverBg: $highlightBg !default;
$tableHeaderCellHighlightTextHoverColor: $highlightTextColor !default;
$tableSortableColumnBadgeSize:1.143rem !default;

$tableBodyRowBg:$shade000 !default;
$tableBodyRowTextColor:$shade700 !default;
$tableBodyRowEvenBg:scale-color($tableBodyRowBg, $lightness: -1%) !default;
$tableBodyRowHoverBg:$hoverBg !default;
$tableBodyRowTextHoverColor:$shade700 !default;
$tableBodyCellBorder:1px solid $shade200 !default;
$tableBodyCellBorderWidth:0 0 1px 0 !default;
$tableBodyCellPadding:1rem 1rem !default;

$tableFooterCellPadding:1rem 1rem !default;
$tableFooterCellBg:$shade100 !default;
$tableFooterCellTextColor:$shade800 !default;
$tableFooterCellFontWeight:700 !default;
$tableFooterCellBorder:1px solid $shade300 !default;
$tableFooterCellBorderWidth:0 0 1px 0 !default;
$tableResizerHelperBg:$primaryColor !default;

$tableFooterBorder:1px solid $shade300 !default;
$tableFooterBorderWidth:0 0 1px 0 !default;
$tableFooterBg:$shade100 !default;
$tableFooterTextColor:$shade800 !default;
$tableFooterFontWeight:700 !default;
$tableFooterPadding:1rem 1rem !default;

$tableCellContentAlignment:left !default;
$tableTopPaginatorBorderWidth:0 0 1px 0 !default;
$tableBottomPaginatorBorderWidth:0 0 1px 0 !default;

$tableScaleSM:0.5 !default;
$tableScaleLG:1.25 !default;

//dataview
$dataViewContentPadding:0 !default;
$dataViewContentBorder:0 none !default;
$dataViewListItemBorder:solid $shade300 !default;
$dataViewListItemBorderWidth:0 0 1px 0 !default;

//schedule
$fullCalendarEventBg:$primaryDarkColor !default;
$fullCalendarEventBorderColor: $primaryDarkColor !default;
$fullCalendarEventBorder:1px solid $primaryDarkColor !default;
$fullCalendarEventTextColor:$primaryTextColor !default;

//tree
$treeContainerPadding:0.286rem !default;
$treeNodePadding:0.143rem !default;
$treeNodeContentPadding:.5rem !default;
$treeNodeChildrenPadding:0 0 0 1rem !default;
$treeNodeIconColor:$shade600 !default;

//timeline
$timelineVerticalEventContentPadding:0 1rem !default;
$timelineHorizontalEventContentPadding:1rem 0 !default;
$timelineEventMarkerWidth:1rem !default;
$timelineEventMarkerHeight:1rem !default;
$timelineEventMarkerBorderRadius:50% !default;
$timelineEventMarkerBorder:2px solid $primaryColor !default;
$timelineEventMarkerBackground:$primaryTextColor !default;
$timelineEventConnectorSize:2px !default;
$timelineEventColor:$shade300 !default;

//org chart
$organizationChartConnectorColor:$shade300 !default;

//message
$messageMargin:1rem 0 !default;
$messagePadding:1.25rem 1.75rem !default;
$messageBorderWidth:0 0 0 6px !default;
$messageIconFontSize:1.5rem !default;
$messageTextFontSize:1rem !default;
$messageTextFontWeight:400 !default;

//inline message
$inlineMessagePadding:$inputPadding !default;
$inlineMessageMargin:0 !default;
$inlineMessageIconFontSize:1rem !default;
$inlineMessageTextFontSize:1rem !default;
$inlineMessageBorderWidth:0px !default;

//toast
$toastIconFontSize:2rem !default;
$toastMessageTextMargin:0 0 0 1rem !default;
$toastMargin:0 0 1rem 0 !default;
$toastPadding:1rem !default;
$toastBorderWidth:0 0 0 6px !default;
$toastShadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !default;
$toastOpacity:.9 !default;
$toastTitleFontWeight:700 !default;
$toastDetailMargin:$inlineSpacing 0 0 0 !default;

//severities
$infoMessageBg: #b3e5fc !default;
$infoMessageBorder: solid scale-color($infoMessageBg, $lightness: -50%) !default;
$infoMessageTextColor: scale-color($infoMessageBg, $lightness: -75%) !default;
$infoMessageIconColor: scale-color($infoMessageBg, $lightness: -75%) !default;
$successMessageBg: #c8e6c9 !default;
$successMessageBorder: solid scale-color($successMessageBg, $lightness: -50%) !default;
$successMessageTextColor: scale-color($successMessageBg, $lightness: -75%) !default;
$successMessageIconColor: scale-color($successMessageBg, $lightness: -75%) !default;
$warningMessageBg: #ffecb3 !default;
$warningMessageBorder: solid scale-color($warningMessageBg, $lightness: -50%) !default;
$warningMessageTextColor: scale-color($warningMessageBg, $lightness: -75%) !default;
$warningMessageIconColor: scale-color($warningMessageBg, $lightness: -75%) !default;
$errorMessageBg: #ffcdd2 !default;
$errorMessageBorder: solid scale-color($errorMessageBg, $lightness: -50%) !default;
$errorMessageTextColor: scale-color($errorMessageBg, $lightness: -75%) !default;
$errorMessageIconColor: scale-color($errorMessageBg, $lightness: -75%) !default;

//overlays
$overlayContentBorder:0 none !default;
$overlayContentBg:$panelContentBg !default;
$overlayContainerShadow: 0 1px 3px rgba(0, 0, 0, 0.3) !default;

//dialog
$dialogHeaderBg:$shade000 !default;
$dialogHeaderBorder:0 none !default;
$dialogHeaderTextColor:$shade800 !default;
$dialogHeaderFontWeight:700 !default;
$dialogHeaderFontSize:1.25rem !default;
$dialogHeaderPadding:1.5rem !default;
$dialogContentPadding:0 1.5rem 2rem 1.5rem !default;
$dialogFooterBorder:0 none !default;
$dialogFooterPadding:0 1.5rem 1.5rem 1.5rem !default;

//confirmpopup
$confirmPopupContentPadding:$panelContentPadding !default;
$confirmPopupFooterPadding:0 1.25rem 1.25rem 1.25rem !default;

//tooltip
$tooltipBg:$shade600 !default;
$tooltipTextColor:$shade000 !default;
$tooltipPadding:$inputPadding !default;

//steps
$stepsItemBg:$shade000 !default;
$stepsItemBorder:1px solid $shade200 !default;
$stepsItemTextColor:$shade600 !default;
$stepsItemNumberWidth:2rem !default;
$stepsItemNumberHeight:2rem !default;
$stepsItemNumberFontSize:1.143rem !default;
$stepsItemNumberColor:$shade700 !default;
$stepsItemNumberBorderRadius:50% !default;
$stepsItemActiveFontWeight:700 !default;

//progressbar, progressspinner
$progressBarHeight:1.5rem !default;
$progressBarBorder:0 none !default;
$progressBarBg:$shade300 !default;
$progressBarValueBg:$primaryColor !default;
$progressBarValueTextColor:$primaryTextColor !default;

$progressSpinnerStrokeColor:$errorMessageTextColor !default;
$progressSpinnerColorOne:$errorMessageTextColor !default;
$progressSpinnerColorTwo:$infoMessageTextColor !default;
$progressSpinnerColorThree:$successMessageTextColor !default;
$progressSpinnerColorFour:$warningMessageTextColor !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth:12.5rem !default;
$menuBg:$shade000 !default;
$menuBorder:1px solid $shade300 !default;
$menuTextColor:$shade700 !default;
$menuitemPadding:.75rem 1.25rem !default;
$menuitemBorderRadius:0 !default;
$menuitemTextColor:$shade700 !default;
$menuitemIconColor:$shade600 !default;
$menuitemTextHoverColor:$shade700 !default;
$menuitemIconHoverColor:$shade600 !default;
$menuitemHoverBg:$hoverBg !default;
$menuitemTextFocusColor:$shade700 !default;
$menuitemIconFocusColor:$shade600 !default;
$menuitemFocusBg:$shade300 !default;
$menuitemTextActiveColor:$highlightTextColor !default;
$menuitemIconActiveColor:$highlightTextColor !default;
$menuitemActiveBg:$highlightBg !default;
$menuitemActiveFocusBg:$highlightFocusBg !default;
$menuitemSubmenuIconFontSize:.875rem !default;
$submenuHeaderMargin:0 !default;
$submenuHeaderPadding:.75rem 1.25rem !default;
$submenuHeaderBg:$shade000 !default;
$submenuHeaderTextColor:$shade800 !default;
$submenuHeaderBorderRadius:0 !default;
$submenuHeaderFontWeight:700 !default;
$overlayMenuBg:$menuBg !default;
$overlayMenuBorder:0 none !default;
$overlayMenuShadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !default;
$verticalMenuPadding:.25rem 0 !default;
$menuSeparatorMargin:.25rem 0 !default;

$breadcrumbPadding:1rem !default;
$breadcrumbBg:$menuBg !default;
$breadcrumbBorder:$menuBorder !default;
$breadcrumbItemTextColor:$menuitemTextColor !default;
$breadcrumbItemIconColor:$menuitemIconColor !default;
$breadcrumbLastItemTextColor:$menuitemTextColor !default;
$breadcrumbLastItemIconColor:$menuitemIconColor !default;
$breadcrumbSeparatorColor:$menuitemTextColor !default;

$horizontalMenuPadding:.5rem !default;
$horizontalMenuBg:$shade100 !default;
$horizontalMenuBorder:$menuBorder !default;
$horizontalMenuTextColor:$menuTextColor !default;
$horizontalMenuRootMenuitemPadding:$menuitemPadding !default;
$horizontalMenuRootMenuitemBorderRadius:$borderRadius !default;
$horizontalMenuRootMenuitemTextColor:$menuitemTextColor !default;
$horizontalMenuRootMenuitemIconColor:$menuitemIconColor !default;
$horizontalMenuRootMenuitemTextHoverColor:$menuitemTextHoverColor !default;
$horizontalMenuRootMenuitemIconHoverColor:$menuitemIconHoverColor !default;
$horizontalMenuRootMenuitemHoverBg:$menuitemHoverBg !default;
$horizontalMenuRootMenuitemTextActiveColor:$menuitemTextActiveColor !default;
$horizontalMenuRootMenuitemIconActiveColor:$menuitemIconActiveColor !default;
$horizontalMenuRootMenuitemActiveBg:$menuitemActiveBg !default;

//badge and tag
$badgeBg:$primaryColor !default;
$badgeTextColor:$primaryTextColor !default;
$badgeMinWidth:1.5rem !default;
$badgeHeight:1.5rem !default;
$badgeFontWeight:700 !default;
$badgeFontSize:.75rem !default;

$tagPadding:.25rem .4rem !default;

//carousel
$carouselIndicatorsPadding:1rem !default;
$carouselIndicatorBg:$shade400 !default;
$carouselIndicatorHoverBg:$shade500 !default;
$carouselIndicatorBorderRadius:0 !default;
$carouselIndicatorWidth:2rem !default;
$carouselIndicatorHeight:.5rem !default;

//galleria
$galleriaMaskBg:rgba(0,0,0,0.9) !default;
$galleriaCloseIconMargin:.5rem !default;
$galleriaCloseIconFontSize:2rem !default;
$galleriaCloseIconBg:transparent !default;
$galleriaCloseIconColor:$shade100 !default;
$galleriaCloseIconHoverBg:rgba(255,255,255,0.1) !default;
$galleriaCloseIconHoverColor:$shade100 !default;
$galleriaCloseIconWidth:4rem !default;
$galleriaCloseIconHeight:4rem !default;
$galleriaCloseIconBorderRadius:50% !default;

$galleriaItemNavigatorBg:transparent !default;
$galleriaItemNavigatorColor:$shade100 !default;
$galleriaItemNavigatorMargin:0 .5rem !default;
$galleriaItemNavigatorFontSize:2rem !default;
$galleriaItemNavigatorHoverBg:rgba(255,255,255,0.1) !default;
$galleriaItemNavigatorHoverColor:$shade100 !default;
$galleriaItemNavigatorWidth:4rem !default;
$galleriaItemNavigatorHeight:4rem !default;
$galleriaItemNavigatorBorderRadius:$borderRadius !default;

$galleriaCaptionBg:rgba(0,0,0,.5) !default;
$galleriaCaptionTextColor:$shade100 !default;
$galleriaCaptionPadding:1rem !default;

$galleriaIndicatorsPadding:1rem !default;
$galleriaIndicatorBg:$shade200 !default;
$galleriaIndicatorHoverBg:$shade500 !default;
$galleriaIndicatorBorderRadius:50% !default;
$galleriaIndicatorWidth:1rem !default;
$galleriaIndicatorHeight:1rem !default;
$galleriaIndicatorsBgOnItem:rgba(0,0,0,.5) !default;
$galleriaIndicatorBgOnItem:rgba(255,255,255,.4) !default;
$galleriaIndicatorHoverBgOnItem:rgba(255,255,255,.6) !default;

$galleriaThumbnailContainerBg:rgba(0,0,0,.9) !default;
$galleriaThumbnailContainerPadding:1rem .25rem !default;
$galleriaThumbnailNavigatorBg:transparent !default;
$galleriaThumbnailNavigatorColor:$shade100 !default;
$galleriaThumbnailNavigatorHoverBg:rgba(255,255,255,0.1) !default;
$galleriaThumbnailNavigatorHoverColor:$shade100 !default;
$galleriaThumbnailNavigatorBorderRadius:50% !default;
$galleriaThumbnailNavigatorWidth:2rem !default;
$galleriaThumbnailNavigatorHeight:2rem !default;

//divider
$dividerHorizontalMargin:1rem 0 !default;
$dividerHorizontalPadding:0 1rem !default;
$dividerVerticalMargin:0 1rem !default;
$dividerVerticalPadding:1rem 0 !default;
$dividerSize:1px !default;
$dividerColor:$shade300 !default;

//avatar
$avatarBg:$shade300 !default;
$avatarTextColor:$textColor !default;

//chip
$chipBg:$shade300 !default;
$chipTextColor:$textColor !default;
$chipBorderRadius: 16px !default;
$chipFocusBg:$shade400 !default;
$chipFocusTextColor:$textColor !default;

//scrollTop
$scrollTopBg:rgba(0,0,0,0.7) !default;
$scrollTopHoverBg:rgba(0,0,0,0.8) !default;
$scrollTopWidth:3rem !default;
$scrollTopHeight:3rem !default;
$scrollTopBorderRadius:50% !default;
$scrollTopFontSize:1.5rem !default;
$scrollTopTextColor:$shade100 !default;

//skeleton
$skeletonBg:$shade300 !default;
$skeletonAnimationBg:rgba(255,255,255,0.4) !default;

//splitter
$splitterGutterBg:$shade100 !default;
$splitterGutterHandleBg:$shade300 !default;

//speeddial
$speedDialButtonWidth: 4rem !default;
$speedDialButtonHeight: 4rem !default;
$speedDialButtonIconFontSize: 1.3rem !default;
$speedDialActionWidth: 3rem !default;
$speedDialActionHeight: 3rem !default;
$speedDialActionBg: $shade700 !default;
$speedDialActionHoverBg: #022354  !default;
$speedDialActionTextColor: #fff !default;
$speedDialActionTextHoverColor: #fff !default;

//dock
$dockActionWidth: 4rem !default;
$dockActionHeight: 4rem !default;
$dockItemPadding: .5rem !default;
$dockItemBorderRadius:$borderRadius !default;
$dockCurrentItemMargin: 1.5rem !default;
$dockFirstItemsMargin: 1.3rem !default;
$dockSecondItemsMargin: 0.9rem !default;
$dockBg: rgba(255,255,255,.1) !default;
$dockBorder: 1px solid rgba(255,255,255,0.2) !default;
$dockPadding: .5rem .5rem !default;
$dockBorderRadius: .5rem !default;

//image
$imageMaskBg:rgba(0,0,0,0.9) !default;
$imagePreviewToolbarPadding:1rem !default;
$imagePreviewIndicatorColor:#f8f9fa !default;
$imagePreviewIndicatorBg:rgba(0,0,0,0.5) !default;
$imagePreviewActionIconBg:transparent !default;
$imagePreviewActionIconColor:#f8f9fa !default;
$imagePreviewActionIconHoverBg:rgba(255,255,255,0.1) !default;
$imagePreviewActionIconHoverColor:#f8f9fa !default;
$imagePreviewActionIconWidth:3rem !default;
$imagePreviewActionIconHeight:3rem !default;
$imagePreviewActionIconFontSize:1.5rem !default;
$imagePreviewActionIconBorderRadius:50% !default;

:root {
    font-family:'Inter var', sans-serif;
    font-feature-settings: "cv02","cv03","cv04","cv11";
    font-variation-settings: normal;
    --font-family:'Inter var', sans-serif;
    --font-feature-settings: "cv02","cv03","cv04","cv11";
    --surface-a:#{$shade000};
    --surface-b:#{$shade100};
    --surface-c:#{$shade200};
    --surface-d:#{$shade300};
    --surface-e:#{$shade000};
    --surface-f:#{$shade000};
    --text-color:#{$shade800};
    --text-color-secondary:#{$shade600};
    --primary-color:#{$primaryColor};
    --primary-color-text:#{$primaryTextColor};
    --primary-light-color:#{$primaryLightColor};
    --primary-lighter-color:#{$primaryLighterColor};
    --primary-dark-color: #{$primaryDarkColor};
    --surface-0: #ffffff;
    --surface-50: #f2f4f6;
    --surface-100: #d9dbdd;
    --surface-200: #c1c3c4;
    --surface-300: #a9aaac;
    --surface-400: #919293;
    --surface-500: #797a7b;
    --surface-600: #606162;
    --surface-700: #48505c;
    --surface-800: #343d4a;
    --surface-900: #293241;
    --gray-50: #f2f4f6;
    --gray-100: #d9dbdd;
    --gray-200: #c1c3c4;
    --gray-300: #a9aaac;
    --gray-400: #919293;
    --gray-500: #797a7b;
    --gray-600: #606162;
    --gray-700: #484949;
    --gray-800: #303031;
    --gray-900: #181818;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#F2F4F6;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#dee2e6;
    --graph-main-color: #5BC1CD;
    --surface-hover: #{$hoverBg};
    --focus-ring: #{$focusShadow};
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    color-scheme: light;
}